.buy
    overflow: hidden
    padding: 100px 0 0 0
    &-grid
        width: 100%
        max-width: 1015px
        margin: 0 auto
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-template-rows: 1fr auto 
        gap: 1rem
        position: relative
        margin-bottom: 1rem
        &-coin
            z-index: 0
            position: absolute
            left: calc(50% - 750px) 
            top: -250px
        &-item
            margin: 0 auto
            z-index: 1
            position: relative
            width: 100%
            max-width: 500px
            text-align: center
            border-radius: 1rem
            overflow: hidden
            background-color: var(--dark)
            &-halftone
                position: absolute
                top: -2px
                left: -2px
                pointer-events: none
                width: 95%
            &-raised
                z-index: 2
                position: relative
            &-title
                font-family: "Barlow Condensed"
                font-size: 1.5rem
                margin-top: 2rem
                margin-bottom: 2rem
            &-img
                height: 80px
                margin-bottom: 1rem
            &-description
                padding: 0 1.5rem
                font-weight: 600
                font-size: .85rem
                height: 70px
                margin-bottom: 1rem
                display: flex
                flex-direction: column
                justify-content: center
                &-subtext
                    margin-top: .25rem
                    font-style: italic
                    color: var(--dark4)
                    font-size: .7rem
                &-copyAddress
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 100%
                    margin: .5rem auto 0 auto
                    padding: .5rem
                    border-radius: .7rem
                    border: solid 2px var(--background-color)
                    background-color: var(--background-color)
                    cursor: pointer
                    &.copySuccess
                        animation: copy .5s 
                    span
                        flex-grow: 1
                        overflow: hidden
                        text-overflow: ellipsis 
                        font-size: .7rem
                    img
                        width: 20px
                        height: 20px
                        margin: 0 .5rem 0 1rem
            &-link-section
                background-color: var(--dark2)
                padding: 1rem 0
                button
                    font-size: .9rem
                    color: var(--dark4)
                    padding: .8rem 0
                    width: 100%
                    max-width: 195px
                    border-radius: 10rem
                    border: solid 2px var(--dark3)
                    transition: .2s ease
                    &:hover
                        border: solid 2px var(--dark4)
                        color: white

        &-help        
            margin: 0 auto
            background-color: var(--darkerBG)
            border-radius: 1rem
            padding: 2rem
            grid-column: 1/-1
            width: 100%
            &-buttons
                display: grid
                grid-template-columns: 200px
                gap: 1rem
                justify-content: center
                button
                    width: 100%
            &-popup
                display: none
                background: rgba(0,0,0,0.5)
                position: fixed
                top: 0
                left: 0
                width: 100%
                height: 100%
                z-index: 1000
                &.active
                    display: block
                &-scroll
                    height: 100%
                    overflow-y: auto
                    &-table
                        display: table
                        width: 100%
                        min-height: 100%
                        &-wrap
                            width: 100%
                            display: table-cell
                            vertical-align: middle
                            padding: 2rem 0
                &-modal
                    max-width: 560px
                    margin: 0 auto
                    border-radius: 1rem
                    padding: 2rem
                    background: var(--dark)
                    h2
                        display: flex
                        justify-content: space-between
                        margin-right: -.5rem
                        margin-bottom: 1rem
                        font-size: 2rem
                        button
                            padding: .5rem
                            font-size: 3rem
                            line-height: 1rem
                            opacity: .3
                            &:hover
                                opacity: 1
                    ol
                        padding-left: 1rem
                        li
                            margin-bottom: 1rem
                            line-height: 2rem
                            &:last-child
                                margin: 0
                        span
                            padding: .15rem .5rem
                            border-radius: .5rem
                            background: var(--purple)
                        a
                            font-weight: bold
                            color: var(--purple)
                            background: var(--purple)
                            background: linear-gradient(90deg,#33eded ,#148fff ,#d325ff ,#fc6b20 ,#ffd703 )
                            -webkit-background-clip: text
                            -webkit-text-fill-color: transparent
                            text-decoration: underline
                    &-lightText
                        color: var(--dark4)


@keyframes copy
    0%
        border: solid 2px var(--background-color)
    50%
        border: solid 2px var(--yellow)
    100%
        border: solid 2px var(--background-color)
