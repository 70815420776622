@media screen and ( max-width: 1120px )
    // ---Fees Section---
    .fees
        &-background-img
            left: calc(50% + 200px)
        &-overflow
            &-img
                left: calc(50% + 100px)
    // ---Buy Section---
    .buy
        &-section-description
            padding: 0 6rem
        &-grid
            &-coin
                left: -150px
                top: -175px
                width: 300px
    // ---News Section---
    .news
        &-graphic
            width: 225px
            &-twitter
                display: none
                left: 0
            &-medium
                top: 700px
                left: calc(50% - 500px)
    .soon
        &-graphic
            width: 700px
            left: calc(50% - 350px)
            &-portfolio
                margin: auto

    .meme
        &-graphic
            width: 600px
            left: calc(50% - 300px)
            &-portfolio
                margin: auto

    .earnings
        &-graphic
            width: 500px
            left: calc(50% - 250px)
            &-earnings
                margin: auto





@media screen and ( max-width: 800px )
    // ---Global---
    .sectionPadding
        padding-left: 1.5rem
        padding-right: 1.5rem
    .section-description
        padding-left: 3rem
        padding-right: 3rem
    // ---Hero Section---
    .hero
        padding-bottom: 0
        &-CTAs
            h2
                padding-left: 3rem
                padding-right: 3rem

    // ---Stats Section---
    .coinStats
        grid-template-columns: 1fr

    // ---WhatIsIt Section---
    .whatIsIt
        &-content
            grid-template-columns: 1fr

    // ---Fees Section---
    .fees
        &-content
            &-blocks
                display: block
                &-item
                    padding: 2rem 0
                    &:nth-of-type(1), &:nth-of-type(2)
                        border-right: none
                        border-bottom: solid 3px var(--dark2)
        &-background-img
            left: 50%
            transform: translateX(-50%)
        &-overflow
            padding-top: 6rem
            &-img
                left: 50%
                transform: translateX(-50%)
        & .section-description
            text-shadow: 0 2px 10px  var(--dark)
    // ---Buy Section---
    .buy
        &-section-description
            padding: 0 1.5rem

            margin-bottom: 8rem
        &-grid
            grid-template-columns: 1fr
            &-help
                max-width: 500px
                &-buttons
                    grid-template-columns: 200px
            &-coin
                left: -80px
                top: -180px
            &-item
                &-description
                    font-size: 1rem

    // ---News Section---
    .news
        padding-bottom: 0rem
        &-dataFrame
            height: 300px
        &-graphic
            // display: none

    // ---Roadmap Section---
    .roadmap
        grid-template-columns: 1fr
        overflow: hidden
        grid-template-areas: "sticky" "timeline"
        &-sticky
            position: static
            margin-bottom: -4rem
            img
                width: 80%
    .soon
        grid-template-columns: 1fr
        grid-template-areas: "sticky" "graphic-wrapper"
        gap: 2em
        margin-bottom: 6em
        margin-top: 2em
        &-sticky
            position: static
            margin-bottom: -4rem
            margin-top: 10rem
            order: 2
        &-graphic-wrapper
            margin: 0 auto 6em auto
            width: 100%
            order: 1
        &-graphic
            top: 0px
            position: static
            width: 100%

    .meme
        grid-template-columns: 1fr
        grid-template-areas: "sticky" "graphic-wrapper"
        gap: 2em
        margin-bottom: 6em
        margin-top: 6em
        &-sticky
            position: static
            margin-bottom: 0rem
            margin-top: 22rem
            order: 2
        &-graphic-wrapper
            margin: auto
            width: 100%
            order: 1
        &-graphic
            top: 0px
            position: static
            width: 100%

    .earnings
        grid-template-columns: 1fr
        grid-template-areas: "sticky" "graphic-wrapper"
        gap: 2em
        margin-bottom: 8em
        &-sticky
            position: static
            margin-bottom: -4rem
            margin-top: 18rem
        &-graphic-wrapper
            margin: auto
            width: 100%
        &-graphic
            top: 0px
            margin: auto
            width: 100%

@media screen and ( max-width: 550px )
    // ---Global Section---
    .sectionPadding
        padding-left: 1rem
        padding-right: 1rem
    .section-title
        font-size: 3rem
    .section-description
        padding-left: 1rem
        padding-right: 1rem

    .soon
        &-graphic-wrapper
            margin: 0 auto 0em auto

    // ---Hero Section---
    .hero
        &-nav
            &-item
                margin: 0 .5rem
                font-size: .8rem
                &-dropdown-left
                    left: 0
                    transform: none
                &-dropdown-right
                    left: initial
                    right: 0
                    transform: none
        &-logo
            width: 300px

        &-O1T-Text
            margin-top: -5rem
            margin-bottom: 2rem
        &-CTAs
            h2
                font-size: 1.25rem
                padding-left: 1rem
                padding-right: 1rem
            &-buttons
                grid-template-columns: 150px
            &-links
                flex-direction: column
        .hero
            padding-bottom: 0

    // ---Buy Section---
    .buy
        &-section-description
            padding: 0 1rem
        &-grid
            &-coin
                left: -100px

    .meme
        grid-template-columns: 1fr
        grid-template-areas: "sticky" "graphic-wrapper"
        gap: 2em
        margin-bottom: 6em
        margin-top: 6em
        &-sticky
            position: static
            margin-bottom: 0rem
            margin-top: 16rem
            order: 2
        &-graphic-wrapper
            margin: auto
            width: 100%
            order: 1
        &-graphic
            top: 0px
            position: static
            width: 100%

    .earnings
        grid-template-columns: 1fr
        grid-template-areas: "sticky" "graphic-wrapper"
        gap: 2em
        &-sticky
            position: static
            margin-bottom: -4rem
            margin-top: 16rem
        &-graphic-wrapper
            margin: auto
            width: 100%
        &-graphic
            top: 0px
            position: static
            width: 100%

@media screen and ( max-width: 320px )
    // ---Global Section---
    .section-title
        font-size: 3rem
    .section-description
        font-size: 1rem
    // ---Hero Section---
    .hero
        &-logo
            position: relative
            left: -10px
            top: 10px