.whatIsIt
    padding-top: 5rem
    padding-bottom: 3rem
    overflow: hidden
    background-image: url(../assets/pattern.jpg)
    &-content
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        margin: auto
        margin-top: 5rem
        margin-bottom: 5rem
        max-width: 1200px
        gap: 2rem
        &-item
            width: 100%
            max-width: 350px
            margin: 0 auto
            text-align: center
            padding: 1rem
            img
                height: 200px
            &-title
                font-weight: bold
                font-size: 1.5rem
                margin-bottom: .5rem
