.coinStats
    width: 100%
    max-width: 900px
    margin-left: auto
    margin-right: auto
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 1rem
    overflow: hidden
    &-item
        background-color: var(--dark)
        border-radius: 1rem
        padding: 2rem 2rem 1.5rem 2rem
        &-text
            text-align: center
            font-family: "Barlow Condensed"
            font-size: 5rem
            line-height: 4rem
            margin-bottom: 1.5rem
            display: flex
            align-items: flex-end
            justify-content: center
            &-rewards
                &-symbol
                    line-height: 1.5rem
                    font-size: 1.5rem
                    margin-left: .25rem
        &-label
            font-weight: 600
            font-size: 1.5rem
            letter-spacing: 2px
            text-align: center


