.soon
    display: grid
    grid-template-columns: 1.1fr 1.15fr
    gap: 5rem
    max-width: 1200px
    width: 100%
    margin-left: auto
    margin-right: auto
    position: relative
    margin-bottom: 12em
    margin-top: 8em
    &-sticky
        align-self: start
        position: sticky
        position: -webkit-sticky
        order: 1
        top: 0rem
        text-align: left
        z-index: 2
        img
            width: 100%
    &-graphic-wrapper
        max-width: 600px
        height: 100px
        margin: 4em auto 0 auto
        position: relative
        order: 2
    &-graphic
        position: absolute
        z-index: 1
        width: 800px
        top: 0
        &-portfolio
            top: -150px
            left: -400px

            
.meme
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 3rem
    max-width: 1200px
    width: 100%
    margin-left: auto
    margin-right: auto
    position: relative
    margin-bottom: 12em
    margin-top: 10em
    h2
        a 
            color: #f4c944
            &:hover
                cursor: pointer
    &-sticky
        align-self: start
        position: sticky
        position: -webkit-sticky
        top: 0rem
        text-align: left
        img
            width: 100%
    &-graphic-wrapper
        max-width: 600px
        height: 100px
        margin: 0 auto
        position: relative
    &-graphic
        position: absolute
        z-index: 0
        width: 600px
        top: 0
        &-astro
            top: -100px
            left: -350px
