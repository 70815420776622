.hero
    padding: 3rem 2rem
    position: relative
    text-align: center
    overflow: hidden
    &-threejs
        pointer-events: none
        width: 100%
        height: 100%
        display: block
        position: absolute
        top: 0
        left: 0
    &-nav
        text-align: center
        position: relative
        &-item
            height: 32px
            cursor: pointer
            display: inline-flex
            align-items: center
            margin: 0 1.5rem
            padding: .25rem
            position: relative
            font-weight: 600
            &:first-child
                margin-left: 0
            &:last-child
                margin-right: 0
            &-dropdown
                position: absolute
                top: 2rem
                left: 50%
                transform: translateX(-50%)
                background: var(--dark)
                border-radius: .5rem
                padding: 1rem
                gap: 1rem
                z-index: 10
                display: none      
                text-align: left               
                a
                    display: flex
                    align-items: center
                    opacity: .5
                    white-space: nowrap
                    img
                        width: 15px
                        margin-right: .5rem
                    &:hover
                        opacity: 1
            &:hover &-dropdown
                display: grid
        &-language
            position: absolute
            right: 3rem
            top: -.25rem
            background: var(--dark)
            padding: .5rem 1rem
            border-radius: .5rem
            font-weight: 600
    &-logo
        display: inline-block
        position: relative
        animation: logoScale 2.5s ease-in-out infinite
        pointer-events: none
        height: 300px
        img
            width: 100%
            max-width: 550px
            margin: 1rem 0 -3.5rem 0
            transform-origin: bottom
        &-on
            position: absolute
            top: 0
            left: 0
            animation: glow 2.5s ease-in-out infinite
    &-O1T-Text
        font-size: 3.5rem
        margin-bottom: 1rem
        background: var(--yellow)
        background-size: 200% auto
        text-fill-color: transparent
        -webkit-text-fill-color: transparent
        -webkit-background-clip: text
        animation: logoText 5s linear infinite
    &-CTAs
        h2
            font-size: 1.5rem
            font-weight: 600
            margin-bottom: 2rem
        &-buttons
            display: grid
            grid-template-columns: repeat(3,150px)
            gap: 1rem
            justify-content: center
            text-align: center
            margin-bottom: 1.5rem
            a 
                display: inline-block
            button
                width: 100%
        &-slippage
            font-weight: 400
            font-style: italic
            color: var(--dark4)
            font-size: .9rem
            margin-bottom: .5rem
            padding: 0 2rem
        &-links
            display: grid
            grid-template-columns: repeat(3,150px)
            gap: 1rem
            justify-content: center
            text-align: center
            flex-direction: row
            a 
                display: inline-block








//----Animations----
@keyframes logoScale 
    0%
        transform: translate3d(0, 0, 0) scale(1)
    50%
        transform: translate3d(0, -10px, 0) scale(1.05)
    100%
        transform: translate3d(0, 0, 0) scale(1)
  

@keyframes glow 
    0%
        opacity: 0
    50%
        opacity: 1
    100%
        opacity: 0
  
@keyframes logoText 
  to 
    background-position: -200% center
