.roadmap
    display: grid
    grid-template-columns: 1.25fr 1fr
    gap: 4rem
    max-width: 1000px
    width: 100%
    margin-left: auto
    margin-right: auto
    grid-template-areas: "timeline sticky"
    &-sticky
        align-self: start
        position: sticky
        position: -webkit-sticky
        top: 2rem
        text-align: center
        grid-area: sticky
        img
            width: 100%
    &-timeline
        max-width: 600px
        margin: 0 auto
        position: relative
        grid-area: timeline
        &-raised
            z-index: 1
            position: relative
        &-border
            position: absolute
            border-left: 6px solid #6B51AE
            left: calc(50% - 3px)
            top: 0
            height: 100%

        &-quarter
            background-color: var(--dark)
            border-radius: 1rem
            padding: 1.5rem 2rem
            margin-bottom: 2rem
            &:last-child
                margin: 0
            &-name
                font-weight: 700
                font-size: 1.8rem
                margin-bottom: 1rem
            ul
                position: relative
            &-border
                position: absolute
                border-left: 2px solid #6B51AE
                pointer-events: none
                left: 9.5px
                top: 50%
                transform: translateY(-50%)
                height: calc(100% - 1rem)
            li
                position: relative
                z-index: 2
                list-style: none
                display: flex
                align-items: center
                margin-bottom: 1rem
                &:first-of-type
                    align-items: flex-start
                &:last-of-type
                    align-items: flex-end
                    margin: 0
                span
                    display: inline-flex
                    justify-content: center
                    align-items: center
                    height: 20px
                    width: 20px
                    min-width: 20px
                    border: solid 2px #6B51AE
                    background-color: var(--dark)
                    border-radius: 10rem
                    margin-right: 1rem

                    img
                        width: 12px

                    &.complete
                        border: solid 2px var(--yellow)
                        background-color: var(--yellow)
                        box-shadow: 0 3px 10px -2px var(--yellow)