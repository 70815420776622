.milestones
    position: relative
    padding: 0
    overflow: hidden
    max-width: 1200px
    margin-left: auto
    margin-right: auto
    &-scroll
        --barFilledPercent: 0%
        width: 100%
        padding: 5rem 0rem 0 0rem
        overflow-x: auto
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
    &-content
        padding: 0 2rem
        display: flex
        justify-content: space-between
        position: relative
        min-width: 900px
        width: 1800px
        margin: 0 auto
    &-progressBar
        position: absolute
        top: 40px
        left: 50%
        transform: translateX(-50%)
        width: calc(100% - 10.5rem)
        height: 10px
        background: var(--dark)
        border-radius: 5rem
        &-filled
            height: 100%
            width: var(--barFilledPercent)
            border-radius: 5rem
            background: linear-gradient(90deg,#33eded ,#148fff ,#d325ff ,#fc6b20 ,#ffd703 )
    &-marker-cont
        position: absolute
        left: 50%
        transform: translateX(-50%)
        width: calc(100% - 10.5rem)
        z-index: 3
        img
            position: absolute
            width: 90px
            top: -70px
            left: calc(var(--barFilledPercent) )
    &-item
        z-index: 2
        width: 150px
        text-align: center
        img
            width: 80%
            height: 86px
            object-fit: contain
            filter: sepia(100%) hue-rotate(195deg) saturate(1.5) brightness(0.5)
        &-price
            font-family: "Barlow Condensed"
            font-size: 2rem
        &-name
            font-family: "Poppins"
            font-weight: 600
            font-size: .8rem
        &.active
            img
                filter: drop-shadow(0 5px 5px  var(--shadowColor))
        &-1
            --shadowColor: rgba(255, 146, 22, 0.527)
        &-2
            --shadowColor: rgba(22, 255, 138, 0.478)
        &-3
            --shadowColor: rgba(63, 161, 253, 0.596)
        &-4
            --shadowColor: rgba(255, 154, 22, 0.582)
        &-5
            --shadowColor: rgba(138, 255, 128, 0.39)
        &-6
            --shadowColor: rgba(63, 161, 253, 0.596)
        &-7
            --shadowColor: rgba(134, 126, 130, 0.596)
        &-8
            --shadowColor: rgba(255, 76, 37, 0.596)

    &-overflowGradient
        pointer-events: none
        display: block
        height: 100%
        width: 100px
        position: absolute
        background: linear-gradient(90deg,var(--background-color) ,var(--background-color-transparent) )
        top: 0
        left: 0
        z-index: 5
        &:last-child
            left: initial
            right: 0
            background: linear-gradient(270deg,var(--background-color) ,var(--background-color-transparent) )


