.news
    padding: 5rem 1rem 10rem 1rem
    position: relative
    background-image: url(../assets/pattern.jpg)
    background-size: 300px
    &-raised
        position: relative
        z-index: 2
    &-selectors
        text-align: center
        margin-bottom: 2rem
        button
            width: 120px
            border-radius: .5rem
            padding: 1rem
            background-color: var(--dark)
            transition: .2s ease
            &:hover
                background-color: var(--dark2)
            img
                width: 30px
                opacity: .3

        &-twitter
            margin-right: 1rem
            &.active
                background: linear-gradient(90deg, #087AFF 0%, #36C3FF 100%)
                img
                    opacity: 1

        &-medium.active
            background: linear-gradient(90deg, #28873A 0%, #56d66d 100%)
            img
                opacity: 1
    &-graphic
        position: absolute
        z-index: 0
        width: 400px
        top: 0
        &-twitter
            top: -200px
            left: calc(50% + 300px)
        &-medium
            top: 650px
            left: calc(50% - 700px)
    &-dataFrame
        background: black
        border-radius: 1rem 0 0 1rem
        overflow: hidden
        overflow-y: auto
        padding: 1rem
        width: 100%
        max-width: 600px
        height: 500px
        margin: 0 auto
        & .hide
            opacity: 0
            position: fixed
            pointer-events: none
            max-height: 0
            top: 0
        &-medium
            *
                color: white !important
            & .medium-widget-article__clap:before
                filter: contrast(0) brightness(5)

