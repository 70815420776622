.earnings {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  gap: 5rem;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 4em;
  margin-bottom: 16em;
}

.earnings-sticky {
  align-self: start;
  position: sticky;
  position: -webkit-sticky;
  margin-top: 6rem;
  text-align: left;
}

.earnings-sticky img {
  width: 100%;
}

.earnings-graphic-wrapper {
  max-width: 600px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

.earnings-graphic {
  position: absolute;
  z-index: 0;
  width: 800px;
  top: 0;
}

.earnings-graphic-tracker {
  top: -50px;
  left: -200px;
  max-height: 441px;
  max-width:500px;
  width: auto;
  height: auto;

}
