.connect
    margin-bottom: 0
    overflow: hidden
    h1
        margin-bottom: 2rem
    &-icons
        text-align: center
        a
            margin: 0 .3rem
            cursor: pointer
            img
                opacity: 0.5
                transition: 0.2s ease
            &:hover img
                opacity: 1

