:root
    --darkerBG: #040404
    --background-color: #000000
    --background-color-transparent: rgba(12, 14, 28, 0)
    --dark: #252525
    --dark2: #373737
    --dark3: #6a6a6a
    --dark4: #9c9c9c
    --yellow: #ffff00
    --purple: #ffff00
    --blue: #ffff00

*
    box-sizing: border-box
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    -webkit-tap-highlight-color: transparent
    margin: 0
    padding: 0
    &:before,
    &:after
        box-sizing: border-box
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
    &:focus
        outline: none


html,
body
    -webkit-font-smoothing: antialiased
    -webkit-overflow-scrolling: touch
    color: white
    background-color: var(--background-color)
    scroll-behavior: smooth
    font-family: 'Poppins', sans-serif

body
    padding-bottom: 4rem
    overflow-x: hidden

button
    border: none
    background: none
    cursor: pointer
    font-family: inherit
    color: inherit



a
    text-decoration: none
    outline: 0
    color: inherit

h1,button
    font-family: 'Barlow Condensed', sans-serif

h1,h2,h3,h4
    text-align: center

h2,h3,h4
    font-family: 'Poppins', sans-serif

p
    text-align: center

::-webkit-scrollbar
    width: 5px

::-webkit-scrollbar-track
    border-radius: 5rem
    background: var(--dark2)

::-webkit-scrollbar-thumb
    border-radius: 5rem
    background: var(--dark3)

.section
    margin-bottom: 6rem

.sectionPadding
    padding-left: 2rem
    padding-right: 2rem

.section-title
    font-size: 4rem
    margin-bottom: 1rem
    text-align: center

.section-description
    font-weight: 600
    font-size: 1.2rem
    margin-bottom: 2rem
    text-align: center


.glowButton
    display: flex
    justify-content: center
    align-items: center
    height: 50px
    padding: .75rem 0
    border-radius: 10rem
    font-size: 1.1rem
    font-weight: 600
    transition: 0.2s ease
    img
        margin-right: .5rem
    &:hover
        transform: scale(1.05)


.yellowButton
    border: solid 3px var(--yellow)
    background: radial-gradient(70% 130% at 50% 100%, var(--yellow), var(--background-color))
    box-shadow: 0 .5rem 30px -10px var(--yellow)
    color: var(--background-color)
    &:hover
        box-shadow: 0 .5rem 40px -10px var(--yellow)

.blueButton
    border: solid 3px var(--dark)
    background: radial-gradient(70% 130% at 50% 100%, var(--dark), var(--background-color))
    box-shadow: 0 .5rem 30px -10px var(--dark)
    &:hover
        box-shadow: 0 .5rem 40px -10px var(--dark)
.purpleButton
    border: solid 3px var(--dark)
    background: radial-gradient(70% 130% at 50% 100%, var(--dark), var(--background-color))
    box-shadow: 0 .5rem 30px -10px var(--dark)
    &:hover
        box-shadow: 0 .5rem 40px -10px var(--dark)


.yellowColor
    color: var(--yellow)
.purpleColor
    color: var(--purple)
.blueColor
    color: var(--blue)


//-------Hero Section--------
@import hero

//-------Coin Stats Section--------
@import coinStats

//-------Milestones Section--------
@import milestones

//-------Milestones Section--------
@import whatIsIt

//-------Earnings Section--------
@import earnings

//-------Fees Section--------
@import fees

//-------Buy Section--------
@import buy

//-------News Section--------
@import news

//-------Coming Soon Section--------
@import soon

//-------Roadmap Section--------
@import roadmap

//-------Connect Section--------
@import connect

//-------Media Queries--------
@import mediaQueries



